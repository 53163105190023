import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-facility-stat';

class StockFacilityStat {

    /** 장비 수리 현황 조회 */
    async getFcltyRepairStat(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Stat` ,{params} );
        return data;
    }
}

export default new StockFacilityStat();